<template>
  <div id="award-report">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col align="right" cols="12" md="12">
            <v-btn
              @click.prevent="refreshPageData"
              color="#a4c639"
              min-width="92"
              rounded
              medium
              class="font-size-h6 px-3 py-3 white--text"
              >Refresh
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- <v-card-subtitle>
          <h5>{{ PageDescription }}</h5>
        </v-card-subtitle> -->
      <v-card-text>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="searchForm"
        >
          <div
            class="d-flex justify-content-center mt-2 mb-3"
            v-if="LoadingFlag"
          >
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-row>
            <v-col cols="12" sm="6" lg="3" md="6">
              <h6><span class="text-danger">*</span> Award Category</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="AwardCategoryOptions"
                :rules="AwardCategoryRules"
                v-model="AwardCategory"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="6" v-if="AwardCategory == 2">
              <h6><span class="text-danger">*</span> Zone</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="ZoneCodeOptions"
                :rules="ZoneCodeRules"
                :loading="ZoneCodeOptionsLoading"
                v-model="ZoneCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="6" v-if="AwardCategory == 2">
              <h6>Lom <small>(optional)</small></h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="LomCodeOptions"
                :rules="LomCodeRules"
                :loading="LomCodeOptionsLoading"
                v-model="LomCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="6">
              <h6>Award Group <small>(optional)</small></h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="AwardGroupOptions"
                :rules="AwardGroupRules"
                v-model="AwardGroup"
                dense
                clearable
                required
                outlined
                multiple
                chips
                deletable-chips
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="6">
              <h6>Award Status <small>(optional)</small></h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="AwardStatusOptions"
                :rules="AwardStatusRules"
                v-model="AwardStatus"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" sm="6" lg="3" md="6">
              <h6>Award Category <small>(optional)</small></h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="AwardCategoryOptions"
                :rules="AwardCategoryRules"
                v-model="AwardCategory"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col> -->
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <v-btn
                :disabled="!valid1"
                @click.prevent="searchForm"
                :loading="SubmitFlag"
                medium
                rounded
                color="#1db954"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Search
              </v-btn>
              <v-btn
                @click.prevent="resetAll"
                medium
                rounded
                color="brown"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
          <h3 class="red--text text-center mt-3 mb-2">
            {{ tableData1.length }} records found.
          </h3>
          <!-- <v-col cols="12" md="12" align="center">
              <v-btn
                v-if="DownloadFlag"
                color="#8a2be2"
                @click.prevent="
                  generateExcel(
                    tableData1,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                >Download</v-btn
              >
              <br />
            </v-col> -->
          <v-container v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col cols="12" sm="6" md="6" lg="4">
                <v-card
                  color="#4d908e"
                  style="height: 160px"
                  v-if="CardLoadingFlag"
                >
                  <br />
                  <v-col cols="12" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="text"
                    ></v-skeleton-loader>
                    <br />
                  </v-col>
                </v-card>
                <v-card
                  color="#4d908e"
                  id="card1"
                  style="height: 160px"
                  v-if="!CardLoadingFlag"
                >
                  <v-card-text id="card-text">
                    <h5>
                      Scored <br />
                      Awards
                    </h5>
                    <h1>{{ TotalScored }}</h1>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="4">
                <v-card
                  color="#43aa8b"
                  style="height: 160px"
                  v-if="CardLoadingFlag"
                >
                  <br />
                  <v-col cols="12" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="text"
                    ></v-skeleton-loader>
                    <br />
                  </v-col>
                </v-card>
                <v-card
                  color="#43aa8b"
                  id="card1"
                  style="height: 160px"
                  v-if="!CardLoadingFlag"
                >
                  <v-card-text id="card-text">
                    <h5>
                      Submitted <br />
                      Awards
                    </h5>
                    <h1>{{ TotalSubmitted }}</h1>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="4">
                <v-card
                  color="#f3722c"
                  style="height: 160px"
                  v-if="CardLoadingFlag"
                >
                  <br />
                  <v-col cols="12" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="text"
                    ></v-skeleton-loader>
                    <br />
                  </v-col>
                </v-card>
                <v-card
                  color="#f3722c"
                  id="card1"
                  style="height: 160px"
                  v-if="!CardLoadingFlag"
                >
                  <v-card-text id="card-text">
                    <h5>Partially Saved Awards</h5>
                    <h1>{{ TotalParticallySaved }}</h1>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <br />
              <v-col align="center" cols="12" md="12">
                <json-excel
                  v-if="tableData1.length > 0 && DownloadFlag"
                  :name="ExcelFileName"
                  :data="tableData1"
                  :fields="ExcelFields"
                >
                  <v-btn
                    color="info"
                    rounded
                    class="font-size-h6 px-3 white--text"
                  >
                    Download
                  </v-btn>
                </json-excel>
              </v-col>
            </v-row>
            <v-row wrap class="mt-0" v-if="RoleId == 2">
              <v-col class="text-center" cols="12" sm="12" lg="12" md="12">
                <v-btn
                  @click="deleteAlert(1)"
                  medium
                  rounded
                  color="#FF8A65"
                  class="font-size-h6 px-4 mr-3 white--text"
                >
                  Unmap Award
                </v-btn>
                <v-btn
                  @click="deleteAlert(2)"
                  medium
                  rounded
                  color="#26A69A"
                  class="font-size-h6 px-4 mr-3 white--text"
                >
                  Delete Answer
                </v-btn>
              </v-col>
            </v-row>
            <v-row wrap class="mt-0">
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.AwardStatusTxt="{ item }">
                    <v-chip :color="item.AwardStatusColor" draggable dark>
                      {{ item.AwardStatusTxt }}
                    </v-chip>
                  </template>
                  <template v-slot:item.ScoreDetail="{ item }">
                    <div v-if="item.JudgeScoreTxt != ''">
                      <p v-html="item.JudgeScoreTxt"></p>
                    </div>
                    <div v-else>
                      <p>NA.</p>
                    </div>
                  </template>
                  <template v-slot:item.FinalScore="{ item }">
                    <div v-if="item.JudgeScoreTxt != ''">
                      <p v-html="item.FinalScore"></p>
                    </div>
                    <div v-else>
                      <p>NA.</p>
                    </div>
                  </template>

                  <template v-slot:item.Actions="{ item }">
                    <v-tooltip bottom v-if="item.PreviewButtonFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewAlert(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="80%">
      <awards-preview
        :previewRecordPrompt="previewRecordPrompt"
        :recordData="recordData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt($event)"
        v-if="previewRecordPrompt"
      ></awards-preview>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AwardsPreview from "@/view/pages/erp/jci-award/AwardsPreview.vue";

export default {
  mixins: [common],
  components: { AwardsPreview },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      OverlayLoadingFlag: false,
      CardLoadingFlag: false,
      search: "",

      ProgessStart: 0,
      uploadPercentage: 0,

      UserInterface: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      TotalParticallySaved: 0,
      TotalSubmitted: 0,
      TotalScored: 0,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      AwardGroupRules: [],
      AwardGroup: [],
      AwardGroupOptions: [],
      AwardGroupOptionsLoading: false,

      AwardCategoryRules: [(v) => !!v || "Award Category is required"],
      AwardCategory: "",
      AwardCategoryOptions: [
        { value: 2, text: "Zone Level" },
        { value: 1, text: "National Level" },
      ],
      AwardCategoryOptionsLoading: false,

      AwardStatusRules: [],
      AwardStatus: "",
      AwardStatusOptions: [
        { value: 2, text: "Scored" },
        { value: 3, text: "Submitted" },
        { value: 4, text: "Partially Saved" },
      ],
      AwardStatusOptionsLoading: false,

      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],

      recordData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      ActiveStatus: "",

      valid: true,

      RoleId: 0,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions called");
      this.LomCodeOptionsLoading = false;
    },
    AwardGroupOptions: function () {
      console.log("watch AwardGroupOptions called");
      this.AwardGroupOptionsLoading = false;
    },
    AwardCategory: function () {
      console.log("watch AwardCategory called");
      this.ZoneCode = "";
      this.AwardGroup = [];
      this.AwardStatus = "";
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    ZoneCode: function () {
      console.log("watch Zone called");
      this.getLomCodeOptions();
      this.AwardGroup = [];
      this.AwardStatus = "";
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    AwardGroup: function () {
      console.log("watch AwardGroup called");
      this.AwardStatus = "";
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    // AwardCategoryOptions: function () {
    //   console.log("watch AwardCategoryOptions called");
    //   this.AwardCategoryOptionsLoading = false;
    // },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "award_report",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getZoneCodeOptions();
      this.getAwardGroupOptions();
      // this.getAwardCategoryOptions();
      // this.searchForm();
    },
    getResult() {
      console.log("getResult called");
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ExcelFields = {};

      this.ZipDownloadFlag = false;
      this.ZipFileName = "";
      this.searchForm();
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      console.log({ ZoneCode });
      if (ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        message += "Zone should not be empty. ";
      }
    },
    getAwardGroupOptions() {
      console.log("getAwardGroupOptions called");
      var selectbox1_source = "AwardGroup";
      var selectbox1_destination = "AwardGroupOptions";
      var selectbox1_url = "api/award-group/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getAwardCategoryOptions() {
      console.log("getAwardCategoryOptions called");
      var selectbox1_source = "AwardCategory";
      var selectbox1_destination = "AwardCategoryOptions";
      var selectbox1_url = "api/award-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    resetAll() {
      console.log("resetAll called");
      this.$refs.form1.reset();
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ExcelFields = {};

      this.ZipDownloadFlag = false;
      this.ZipFileName = "";
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.sweetAlert("warning", message, false);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/lists";

        var award_category = this.AwardCategory > 0 ? this.AwardCategory : null;
        var zone = this.ZoneCode > 0 ? this.ZoneCode : null;
        var lom = this.LomCode > 0 ? this.LomCode : null;
        var award_group = this.AwardGroup.length > 0 ? this.AwardGroup : [];
        var award_status = this.AwardStatus > 0 ? this.AwardStatus : null;

        var upload = {
          UserInterface: 1,
          Zone: zone,
          Lom: lom,
          AwardGroup: award_group,
          AwardCategory: award_category,
          AwardStatus: award_status,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.ProgessStart = 0;
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;

              thisIns.toast("success", output);

              var tr = records.TableData;
              var scored = 0;
              var submitted = 0;
              var particallySaved = 0;
              tr.forEach((row) => {
                var status = row["AwardStatus"];
                console.log({ status });

                if (status == 2) {
                  scored = scored + 1;
                } else if (status == 3) {
                  submitted = submitted + 1;
                } else if (status == 4) {
                  particallySaved = particallySaved + 1;
                }
              });

              thisIns.TotalParticallySaved = particallySaved;
              thisIns.TotalSubmitted = submitted;
              thisIns.TotalScored = scored;
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
            console.log("error=" + error);
          });
      } else {
        var message = "Kindy fill required fields and then search";
        this.toast("error", message);
      }
    },
    deleteAlert(id) {
      console.log("deleteAlert called");
      console.log({ id });

      var n1 = this.selected1.length;
      var tr = this.selected1;
      console.log({ n1 }, "tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.selectedData = tr;
        this.UserInterface = id;
        this.confirmAlert();
      } else {
        var error = "Select atleast One Award to UnMap Awards.";
        this.sweetAlert("error", error, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var n1 = this.selectedData.length;
      var tr = this.selectedData;
      var id = this.UserInterface;
      console.log({ n1 }, "tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var buttonText = id == 1 ? "UnMap Award" : "Delete Answer";
        var htmlTxt = "";

        htmlTxt += "Do you want to UnMap Selected Awards?";
        htmlTxt += "<br/><br/> Awards Count : <b>" + n1 + "</b>";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to UnMap selected Award?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: buttonText,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr, id);
          } else {
            this.sweetAlert("warning", "You cancelled the process", false);
          }
        });
      } else {
        var error = "Atleast One Award is require to UnMap.";
        this.sweetAlert("error", error, false);
      }
    },
    deleteRecord(tr, id) {
      console.log("deleteRecord called");

      console.log({ id });
      var n1 = tr.length;
      console.log({ n1 }, "tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var Award = tr;
        console.log({ Award });

        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;

        var add_url = server_url + "api/judge-award-relation/destroy-answers";

        var upload = {
          UserInterface: id,
          Award: Award,
        };

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var success = "";
        var error = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            success = response.data.success;
            error = response.data.error;
            console.log("success=" + success + ", error=" + error);
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#award-report {
  #card-text {
    text-align: center;
    color: white;
  }

  #card-text h5 {
    font-size: 32px;
  }

  #card-text h1 {
    font-size: 44px;
    font-weight: 900;
  }
}
</style>
